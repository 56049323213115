import {
  createStyles,
  Drawer,
  List,
  Theme,
  useMediaQuery,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { drawerWidth } from '../../helpers/NavHelper';
import {
  routeScreen,
  routeDashboard,
  routeAccountDeletion,
  routeAccountResetPassword,
  routeAccountRestoreUser,
  routePromoCodeGenerate,
  routeManageCuratedMixes,
  routeManageCollectionTiles,
} from '../../helpers/RouteHelper';
import { AuthContext } from '../../hooks/AuthContext';
import { LayoutContext } from '../../hooks/LayoutContext';
import NavPrimaryItem from './NavPrimaryItem';
import { ScreenContext } from '../../hooks/ScreenContext';

const styles = (theme: Theme) =>
  createStyles({
    authStatus: {
      padding: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      'z-index': '30 !important',
    },
    drawerPaper: {
      top: 'auto',
      width: drawerWidth,
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.white,
      zIndex: 30,
    },
    toolbar: theme.mixins.toolbar,
    toolbarSpacing: {
      minHeight: theme.spacing(14),
      [theme.breakpoints.up('sm')]: {
        minHeight: theme.spacing(15),
      },
      [theme.breakpoints.up('md')]: {
        minHeight: theme.spacing(6.5),
      },
    },
    customIcons: {},
    navListContainer: {
      overflow: 'auto',
    },
  });

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
}

const NavPrimary = (props: Props) => {
  const { classes, theme } = props;
  const breakpointIsMdPlus = useMediaQuery(theme.breakpoints.up('md'));
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      'Navigation component must be used within a Auth Context Provider'
    );
  }
  const { signedIn } = authContext;
  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) {
    throw new Error(
      'Navigation component must be used within a Layout Context Provider'
    );
  }
  const { leftDrawerOpen, setLeftDrawerOpen } = layoutContext;
  const screenContext = useContext(ScreenContext);

  if (!screenContext) throw new Error('Admin Screen context is missing');

  const closeDrawer = () => {
    setLeftDrawerOpen(false);
  };

  if (!signedIn) return null;
  return (
    <div role="navigation">
      <Drawer
        id="nav-primary-drawer"
        variant={breakpointIsMdPlus ? 'persistent' : 'temporary'}
        open={leftDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
          root: classes.drawer,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        onClose={closeDrawer}
      >
        <div className={classnames(classes.toolbarSpacing, classes.toolbar)} />
        <div className={classes.navListContainer}>
          <List>
            <NavPrimaryItem
              label="Dashboard"
              path={routeDashboard()}
              pathname={routeDashboard()}
              name="Dashboard"
              key="Dashboard"
              closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
            />
            {screenContext.appStore.screens.map((screen: any) => {
              const screenName = screen?.id || '';
              return (
                <NavPrimaryItem
                  label={screenName}
                  path={routeScreen(screenName)}
                  pathname={routeScreen(screenName)}
                  name={screenName}
                  key={screenName}
                  closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
                />
              );
            })}
            <NavPrimaryItem
              label="Manage Curated Mixes"
              path={routeManageCuratedMixes()}
              pathname={routeManageCuratedMixes()}
              name="Manage Curated Mixes"
              key="Manage Curated Mixes"
              closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
            />
            {/* <NavPrimaryItem
              label="Manage Collection Tiles"
              path={routeManageCollectionTiles()}
              pathname={routeManageCollectionTiles()}
              name="Manage Collection Tiles"
              key="Manage Collection Tiles"
              closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
            /> */}
            <NavPrimaryItem
              label="Account Deletion"
              path={routeAccountDeletion()}
              pathname={routeAccountDeletion()}
              name="Account Deletion"
              key="Account Deletion"
              closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
            />
            <NavPrimaryItem
              label="Reset Password"
              path={routeAccountResetPassword()}
              pathname={routeAccountResetPassword()}
              name="Reset Password"
              key="Reset Password"
              closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
            />
            <NavPrimaryItem
              label="Restore Account"
              path={routeAccountRestoreUser()}
              pathname={routeAccountRestoreUser()}
              name="Restore Account"
              key="Restore Account"
              closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
            />
            <NavPrimaryItem
              label="Promo Code Generator"
              path={routePromoCodeGenerate()}
              pathname={routePromoCodeGenerate()}
              name="Promo Code Generator"
              key="Promo Code Generator"
              closeDrawer={breakpointIsMdPlus ? null : closeDrawer}
            />
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(NavPrimary);
